//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ImageAndText',
  props: {
    left: {
      type: Boolean,
      default: true,
    },
    imgSource: {
      type: String,
      default: '//summitproedu.imgix.net/redesign-assets/pages/about/About_EveryFormat.jpg',
    },
  },
  data () {
    return {
      sizing: '?fit=crop&w=512&h=341.484',
    };
  },
};
