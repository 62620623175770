//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ScrollCard',
  props: {
    card: {
      type: Object,
      default: () => {},
    }, // Pass the card data as a prop
    productType: {
      type: String,
      default: 'VIDEO',
    },
    variant: {
      type: String,
      default: 'default',
    },
    showLocation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    thumbnail () {
      return `url(https://summitproedu.imgix.net/${this.getCourseId()}.jpg?auto=enhance%2cformat&fit=crop&crop=faces&w=292&h=145)`;
    },
    thumbnailMobile () {
      return `url(https://summitproedu.imgix.net/${this.getCourseId()}.jpg?auto=enhance%2cformat&fit=crop&crop=faces&w=206&h=128)`;
    },
    truncatedCourseTitle () {
      const maxCharacters = 67;
      if (this.card.title.length > maxCharacters) {
        return this.card.title.slice(0, maxCharacters) + '...';
      } else {
        return this.card.title;
      }
    },
  },
  methods: {
    async goToCourse () {
      // cards from course recs have product type attached
      if (this.card.productType) {
        await window.open(`/course/${this.getCourseId()}/${this.card.slug}/#${this.card.productType}/${this.card.sessionId}`);
      } else {
        await window.open(`/course/${this.getCourseId()}/${this.card.slug}/#${this.productType}`);
      }
    },
    getCourseId () {
      const allowedTypes = [
        'VIDEO', 'PODCAST', 'TEXT',
      ];
      return allowedTypes.includes(this.productType) || this.variant === 'homepage' ? this.card.id : this.card.course_id;
    },
  },
};
