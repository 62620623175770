//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VideoHero',
  props: {
    backgroundUrl: {
      type: String,
      default: '//d2vu1vyohnm3g3.cloudfront.net/home-video/summit-ceu-course-examples-3.mp4?crop=entropy&fit=crop&h=200&w=600',
    },
    footer: {
      type: Boolean,
      default: false,
    },
  },
};
