//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    productType: {
      type: String,
      default: 'VIDEO',
    },
    cards: {
      type: Array,
      default: () => [],
    },
    variant: {
      type: String,
      default: 'default',
    },
    recommendedCourseCards: {
      type: Boolean,
      default: false,
    },
    showLocation: {
      type: Boolean,
      default: false,
    },
    bottomBorder: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      scrollPosition: 0,
    };
  },
  computed: {
    atStart () {
      return this.scrollPosition === 0;
    },
    atEnd () {
      const cardsContainer = this.$refs.cardsContainer;
      const carousel = this.$refs.carousel;
      return (
        cardsContainer &&
        carousel &&
        cardsContainer.offsetWidth + this.scrollPosition >= carousel.scrollWidth
      );
    },
    seeMoreLink () {
      switch (this.productType) {
        case 'TEXT':
          return 'text';
        case 'LIVE':
          return 'live';
        case 'VIDEO':
          return 'video';
        case 'LIVESTREAM':
          return 'live-streaming';
        case 'WEBINAR':
          return 'webinar';
        case 'PODCAST':
          return 'podcast';
        default:
          return 'video'; // Optional: Handle unknown product types
      }
    },
  },
  mounted () {
    console.log('horizontal scroll bar layout mounted', this.cards);
  },
  methods: {
    scroll (direction) {
      const cardsContainer = this.$refs.cardsContainer;
      if (cardsContainer) {
        const scrollAmount = direction * 800; // Adjust the scroll amount as needed
        this.scrollPosition += scrollAmount;
        cardsContainer.scrollTo({
          left: this.scrollPosition,
          behavior: 'smooth',
        });
      }
    },
  },
};
