//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TrustPilotReviews',
  props: {
    header: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: '',
    },
  },
  mounted () {
    const trustbox = document.getElementById('trustbox');
    window.Trustpilot.loadFromElement(trustbox);
  },
};
