import { render, staticRenderFns } from "./VideoHero.vue?vue&type=template&id=555951ee&scoped=true&"
import script from "./VideoHero.vue?vue&type=script&lang=js&"
export * from "./VideoHero.vue?vue&type=script&lang=js&"
import style0 from "./VideoHero.vue?vue&type=style&index=0&id=555951ee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "555951ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TContainer: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/Container.vue').default})
