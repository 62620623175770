//
//
//
//
//
//


function generateStars (count, starType) {
  const stars = [];
  for (let i = 0; i < count; i++) {
    stars.push(starType);
  }
  return stars;
}

export default {
  name: 'RatingStars',
  props: {
    rating: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    stars () {
      let nearestHalf;
      let wholeNumber;
      const getDecimalVal = this.rating.toString().indexOf('.');
      const decimalPart = this.rating.toString().substring(getDecimalVal + 1);
      if (Number(decimalPart) >= 7) {
        nearestHalf = Math.ceil(this.rating * 2) / 2;
        wholeNumber = Math.ceil(this.rating);
      } else {
        nearestHalf = Math.round(this.rating * 2) / 2;
        wholeNumber = Math.floor(this.rating);
      }
      const hasHalf = (nearestHalf - wholeNumber) === 0.5;
      const emptyNumber = 5 - Math.ceil(this.rating);

      //
      return [].concat(
        generateStars(wholeNumber, 'star'),
        generateStars(hasHalf ? 1 : 0, 'star-half-alt'),
        generateStars(emptyNumber, ['far', 'star'])
      );
    },
  },
};
