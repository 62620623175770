import { render, staticRenderFns } from "./ImageAndText.vue?vue&type=template&id=01786ed4&"
import script from "./ImageAndText.vue?vue&type=script&lang=js&"
export * from "./ImageAndText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TGradientImage: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/GradientImage.vue').default,TTextSection: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/TextSection.vue').default,TContainer: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/Container.vue').default})
