import { render, staticRenderFns } from "./TrustPilotReviews.vue?vue&type=template&id=1160bc63&"
import script from "./TrustPilotReviews.vue?vue&type=script&lang=js&"
export * from "./TrustPilotReviews.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TContainer: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/Container.vue').default})
