//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'GradientSection',
  props: {
    threeCards: {
      type: Boolean,
      default: true,
    },
  },
};
